import { setRangeValue } from "tsparticles-engine";
export class Push {
    constructor() {
        this.default = true;
        this.groups = [];
        this.quantity = 4;
    }
    get particles_nb() {
        return this.quantity;
    }
    set particles_nb(value) {
        this.quantity = setRangeValue(value);
    }
    load(data) {
        if (!data) {
            return;
        }
        if (data.default !== undefined) {
            this.default = data.default;
        }
        if (data.groups !== undefined) {
            this.groups = data.groups.map((t) => t);
        }
        if (!this.groups.length) {
            this.default = true;
        }
        const quantity = data.quantity ?? data.particles_nb;
        if (quantity !== undefined) {
            this.quantity = setRangeValue(quantity);
        }
    }
}
