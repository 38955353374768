import { ExternalInteractorBase, getRangeValue, itemFromArray, } from "tsparticles-engine";
import { Push } from "./Options/Classes/Push";
export class Pusher extends ExternalInteractorBase {
    constructor(container) {
        super(container);
        this.handleClickMode = (mode) => {
            if (mode !== "push") {
                return;
            }
            const container = this.container, options = container.actualOptions, pushOptions = options.interactivity.modes.push;
            if (!pushOptions) {
                return;
            }
            const quantity = getRangeValue(pushOptions.quantity);
            if (quantity <= 0) {
                return;
            }
            const group = itemFromArray([undefined, ...pushOptions.groups]), groupOptions = group !== undefined ? container.actualOptions.particles.groups[group] : undefined;
            container.particles.push(quantity, container.interactivity.mouse, groupOptions, group);
        };
    }
    clear() {
    }
    init() {
    }
    async interact() {
    }
    isEnabled() {
        return true;
    }
    loadModeOptions(options, ...sources) {
        if (!options.push) {
            options.push = new Push();
        }
        for (const source of sources) {
            options.push.load(source?.push);
        }
    }
    reset() {
    }
}
